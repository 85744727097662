import React from 'react';
import '../style/privacy-terms.css';

const Terms = () => {
    return (
        <div className="Privacy-Container">
            <h1>Terms of Use</h1>
            <h2>1. Acceptance of Terms</h2>
            <p>By accessing the Apps-Galaxy website at https://apps-galaxy.com, you agree to be bound by these terms and
                conditions and all applicable laws and regulations. If you do not agree with any of these terms, you are
                prohibited from using or accessing this site. The content of this website is protected by copyright and
                trademark law.</p>

            <h2>2. Use License</h2>
            <p>Apps-Galaxy grants you a temporary license to download one copy of the materials on our website for
                personal, non-commercial transitory use only. This license does not include:</p>
            <ul>
                <li>Modification or copying of the materials.</li>
                <li>Use of the materials for any commercial purpose, or for any public display (commercial or
                    non-commercial).
                </li>
                <li>Attempting to reverse engineer any software contained on the website.</li>
                <li>Removing any copyright or other proprietary notations from the materials.</li>
                <li>Transferring the materials to another person or "mirroring" the materials on any other server.</li>
            </ul>
            <p>This license shall automatically terminate if you violate any of these restrictions and may be terminated
                by Apps-Galaxy at any time. Upon terminating your viewing of these materials or upon the termination of
                this license, you must destroy any downloaded materials in your possession whether in electronic or
                printed format.</p>

            <h2>3. Disclaimer</h2>
            <p>The materials on Apps-Galaxy’s website are provided on an 'as is' basis. Apps-Galaxy makes no warranties,
                expressed or implied, and hereby disclaims and negates all other warranties including, without
                limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or
                non-infringement of intellectual property or other violation of rights.</p>

            <h2>4. Limitations</h2>
            <p>In no event shall Apps-Galaxy or its suppliers be liable for any damages (including, without limitation,
                damages for loss of data or profit, or due to business interruption) arising out of the use or inability
                to use the materials on Apps-Galaxy’s website, even if Apps-Galaxy or an authorized representative has
                been notified orally or in writing of the possibility of such damage.</p>

            <h2>5. Accuracy of Materials</h2>
            <p>The materials appearing on Apps-Galaxy’s website could include technical, typographical, or photographic
                errors. Apps-Galaxy does not warrant that any of the materials on its website are accurate, complete, or
                current. Apps-Galaxy may make changes to the materials contained on its website at any time without
                notice. However, Apps-Galaxy does not make any commitment to update the materials.</p>

            <h2>6. Links</h2>
            <p>Apps-Galaxy has not reviewed all of the sites linked to its website and is not responsible for the
                contents of any such linked site. The inclusion of any link does not imply endorsement by Apps-Galaxy of
                the site. Use of any such linked website is at the user's own risk.</p>

            <h2>7. Modifications to Terms of Use</h2>
            <p>Apps-Galaxy may revise these Terms of Use for its website at any time without notice. By using this
                website, you are agreeing to be bound by the current version of these Terms and Conditions of Use.</p>

            <h2>8. Your Privacy</h2>
            <p>Please be sure to read our Privacy Policy.</p>

            <h2>9. Governing Law</h2>
            <p>Any claim relating to Apps-Galaxy's website shall be governed by the laws of Cyprus without regard to its
                conflict of law provisions.</p>
            <h3>Want to get in touch with us? Feel free to send us a message using the details below.</h3>
            <p>Email : gal.p@apps-galaxy.com</p>
        </div>

    );
};

export default Terms;
